.texte {
    user-select: none;
    max-width: fit-content;
    background: var(--gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    word-wrap: break-word;
    font-family: "SuiGeneris";
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .container {
        max-width: 90vw;
    }
}

/* Desktop */
@media only screen and (min-width: 601px) {
    .container {
        max-width: 60vw;
    }
}