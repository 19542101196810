/* Variables */
:root {
    --gradient: linear-gradient(143deg, #FFD600 0%, #FF8A00 100%);
    --text-color-light-bg: rgb(35, 35, 35);
    --text-color-dark-bg: rgb(220, 220, 220);
    --background-light: #fff;
    --background-dark: #2b1a00;
    --accent-color: #F8AE48;


    accent-color: var(--accent-color);
}

::selection {
    background-color: var(--background-dark);
    color: #FFD600;
}

.loading_page {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.global_grid {
    display: grid;
    place-content: center;
    margin: auto;
    text-align: center;
    gap: 40px;
    max-width: 80vw;
}

.center {
    margin: 0 auto;
}

.global_card {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.10);
    padding: 10px;
    display: flex;
    justify-content: center;
}

.global_navigation_link {
    text-decoration: none;
    color: black;
}

.global_regular_link_light_bg,
.global_regular_link_dark_bg {
    font-weight: 500;
    text-decoration: none;
    transition: 0.2s;
}

.num_de_telef {
  font-size: 18px; /* Override the font size for phone numbers */
}

.global_regular_link_light_bg {
    color: var(--text-color-light-bg);
    border-bottom: 2px solid var(--accent-color);
}

.global_regular_link_dark_bg {
    color: var(--text-color-dark-bg);
    border-bottom: 0px solid var(--accent-color);
}

.global_regular_link_light_bg:hover,
.global_regular_link_dark_bg:hover {
    padding-left: 5px;
    padding-right: 5px;
    transition: 0.2s;
    border-bottom: 1px solid var(--accent-color);
}

.global_card_image {
    max-width: 100%;
    border-radius: 12px 12px 0px 0px;
}

.global_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    width: 450px;
    max-width: 550px;
}

.global_form_label {
    margin-left: 8px;
}

.global_form_label_erreur {
    color: red;
}

.global_text_field {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;
    border: none;
    width: 100%;
    max-width: 100%;
    min-width: 100%;

    border-radius: 12px;
    border: 1px solid #E0E0E0;
}

.global_text_area {
    min-height: 300px;
}

.global_button {
    background: var(--gradient);
    border: none;
    border-radius: 6px;
    padding: 6px 32px;
    font-size: 1.05em;
    font-weight: 600;
    transition: 0.2s;

    margin: auto
}

.global_button:hover {
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.2s;
    scale: 1.05;
}

/* Ordinateur */
@media only screen and (min-width: 601px) {
    .global_grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
        width: 80vw;
    }
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .global_grid {
        grid-template-columns: auto;
    }

    .global_form {
        width: 90vw;
        margin: auto;
    }

    .global_text_area {
        min-height: 150px;
    }
}


.sous_titre {
    font-size: 1.2em;
}
