.container {
  margin-top: 60px;
  /* background: linear-gradient(143deg, #FFD600 0%, #FF8A00 100%); */
  background-color: rgb(35, 35, 35);
  color: rgb(220, 220, 220);
  bottom: 0px;
}

.titre {
  font-family: "SuiGeneris";
}

.infos_contact {
  list-style-type: none;
}

li {
  margin-bottom: 10px;
}
li::before {
  margin-left: -40px;
  margin-right: 10px;
}

#adresse:before {
  content: url('../location.svg');
  vertical-align: middle;

}

#adresse {
  display: flex;
  align-items: flex-start;
}

#telephone:before {
  content: url('../telephone.svg');
  vertical-align: middle;
}

#email:before {
  content: url('../courriel.svg');
  vertical-align: middle;
}

/* Ordinateur */
@media only screen and (min-width: 601px) {
  .container {
    padding: 40px 100px;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .container {
    padding: 40px 10vw;
  }
}

.copyright {
  color: rgb(70, 70, 70);;
  text-align: center; /* Center the text horizontally */
  font-size: 14px;
  /* line-height: 0.5; /1* Adjust the line height for vertical alignment *1/ */
}
