.video_container {
    position: relative;
    object-fit: cover;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    max-height: 100vh;

    /* marge headerbar */
    transform: translateY(-40px);
}

#video {
    min-height: 100%;
    width: 100vw;
}

.image_overlay_video {
    position: fixed;
    overflow: hidden;
    z-index: 100;
    /* transform: translateY(-400px); */
    /* background-color: blue; */
    bottom: 0%;
    /* margin: 0 auto; */
    /* max-height: 100%; */
    max-width: 100vw;
}

.contenu_flex {
    margin-left: auto;
    margin-right: auto;
    max-width: 751px;
    /* width: clamp(0vw, 80vw, 90vw); */
}

.categorie {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 50px;
}

.sous_categorie {
    max-width: 751px;
}

@media only screen and (max-width: 1562px) {
    .contenu_flex, .categorie {
        margin: auto;
        max-width: 751px;
    }

    .contenu_flex {
        width: 90vw;
    }
}
