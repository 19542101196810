@font-face {
  font-family: 'SuiGeneris';
  src: local('SuiGeneris'),
    url('./fonts/sui_generis/sui_generis_rg.otf') format('opentype');
    /* url('./fonts/sui_generis/sui_generis_rg.ttf') format('truetype'); */
    /* url('./fonts/SuiGeneris.woff2') format('woff2'); */
}
@font-face {
  font-family: 'Arimo';
  src: local('Arimo'),
    url('./fonts/arimo/Arimo-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: "Space Grotesk Variable";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;

  /* font-family: Arimo, sans-serif; */
  /* font-size: 15px; */
  /* color: #5d5c5c; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
