.flex_container {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 50px;
    /* column-gap: 50px; */
}

.custom_span {
  display: block;
  font-size: 1.1em;
  font-weight: bold;
  /* Add any other styles as needed */
  padding-top: 30px; /* Add 10px of top padding */
  padding-bottom: 5px; /* Add 20px of bottom padding */
}
