/* SelecteurDeLangue.css */
.language_container {
  white-space: nowrap;
  /* display: inline-flex; */
}


.language_button {
  vertical-align: middle;
  margin-left: 0px;
  margin-right: 0px;
  cursor: pointer; /* Add a pointer cursor to the buttons */
}
