.carousel {
    display: flex;
    flex-direction: column;
    position: relative;
}

/* slider */
.carousel_slider {
    scrollbar-width: none;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

/* tous les div dans le slider */
.carousel_slider>.carousel_slider_item {
    width: 90%;
    margin: 0 5px;
    scroll-snap-align: start;
    flex-shrink: 0;
}

/* images dans le slider */
.carousel_slider>.carousel_slider_item>.carousel_image {
    margin: 0 5px;
    width: 100%;
    object-fit: contain;
    border-radius: 30px;
}

.carousel_slider::-webkit-scrollbar {
    display: none;
}

.bouton {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 1px solid #c1c1c1;
    background: rgba(238, 238, 238, 0.185);
    backdrop-filter: blur(20px);
    transition: 0.2s;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.10);
    scale: 1;

    font-size: 1.5em;
}

/* Ordinateur */
@media only screen and (min-width: 801px) {

    .carousel_slider>.carousel_slider_item>.carousel_image {
        /* pour que quand on clique le bouton ca ne cache pas l'image */
        /* margin-top: 100px; */
    }

    .bouton {
        width: 50px;
        height: 50px;
    }

    .bouton:hover {
        cursor: pointer;
        transition: 0.2s;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
        scale: 1.05;
    }

    #next {
        position: absolute;
        transform: translateY(-50%);
        right: -25px;
        top: 50%
    }

    #back {
        position: absolute;
        transform: translateY(-50%);
        left: -25px;
        top: 50%
    }
}

/* Mobile */
@media only screen and (max-width: 800px) {
    .boutons {
        display: none;
    }
}