.card {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.10);
}

.thumbnail {
    width: 100%;
    /* height: 400px; */
    border-radius: 12px 12px 0px 0px;
    object-fit: cover;
    display: block;
}

.thumbnail_partenaire {
    /* width: 60%; */
    border-radius: 12px 12px 0px 0px;
    object-fit: cover;
    display: block;
}

.texte_carte {
    margin: 10px
}

.titre {
    font-weight: 900;
}

.description {
    color: #353535;
    word-wrap: break-word;
}

/* Ordinateur */
@media only screen and (min-width: 601px) {
    .card {
        max-width: 400px;
    }
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .card {
        width: 80vw;
    }
}
