.container {
    width: 100%;
    overflow: hidden;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    z-index: 100;
    margin-bottom: 40px;
    overflow: visible;
}

.header {
    -webkit-backdrop-filter: blur(40px) saturate(200%);
    backdrop-filter: blur(40px) saturate(200%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "SuiGeneris";
}

.logo_container {
    margin-left: 6px;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.logo {
    margin: 0;
    padding: 10px;
    max-width: calc(100% - 70px);
    fill: black;
}

.logo_text {
    margin: 0;
    margin-top: 0.5rem;
    margin-left: 0px;
    font-size: 1.8rem;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    text-overflow: ellipsis;
    transition: 0.3s;
    line-height: 21px;
    margin-bottom: .5rem;
}
.logo_sous_text {
    margin: 0;
    font-size: 0.95rem;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
    /* text-overflow: ellipsis;
    transition: 0.3s; */
    line-height: 1;
    margin-top: -3.5rem;
}


/* .logo_text:hover {
    transition: 0.3s;
    letter-spacing: 2px;
}

.logo_text:active {
    letter-spacing: -1px;
    transition: 0.3s;
} */

.logo a:hover {
    cursor: pointer;
}

.btn {
    background: none;
    border: none;
    font-size: 1.3em;
    padding: 0px;
    margin-left: 0px;
    margin-right: 10px;
    /* transition: 0.2s; */
}

.btn:hover {
    cursor: pointer;
    /* scale: 1.3;
    transition: 0.2s; */
}

#btn_open {
    color: black;
}

#logout_button {
    background: #d70000;
    color: white;
    padding-left: 20px;
    word-wrap: break-word;
}

.links_container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.350);
    text-align: center;

    backdrop-filter: blur(50px) saturate(150%);
    -webkit-backdrop-filter: blur(50px) saturate(150%);
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);

    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
    animation: close 0.3s;
    animation-timing-function: ease-out;
}

/* @keyframes close {
    from {
        transform: translateY(-50%) translateX(-50%) ;
        width: 100vw;
        height: 100vh;
    }

    to {
        transform: translateY(-50%) translateX(0%) ;
        width: 0;
        height: 0;
    }
} */

@keyframes close {
    from {
        transform: translateY(-50%) translateX(-50%);
    }

    to {
        transform: translateY(-100%) translateX(-50%);
    }
}

.links_container.open {
    transition: 0.2s;
    opacity: 1;
    visibility: visible;
    animation: open 0.3s;
    animation-timing-function: ease-out;
}

/* @keyframes open {
    from {
        transform: translateY(0%) translateX(0%);
        width: 0;
        height: 0;
    }

    to {
        transform: translateY(-50%) translateX(-50%);
        width: 100vw;
        height: 100vh;
    }
} */

@keyframes open {
    from {
        transform: translateY(-100%) translateX(-50%);
    }

    to {
        transform: translateY(-50%) translateX(-50%);
    }
}

.links {
    user-select: none;
    display: block;
    position: absolute;
    margin: 0 auto;
    list-style-type: none;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    font-size: 2em;
    font-weight: 700;
}

.links a {
    white-space: nowrap;
    overflow: hidden;
    max-width: 90vw;
    text-overflow: ellipsis;
}

.links a:hover {
    cursor: pointer;
    background: var(--gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.2s;
}

#btn_close {
    position: absolute;
    top: 20px;
    right: 20px;
}

@media (prefers-reduced-motion) {

    .links_container.open,
    .links_container {
        transition: none;
        animation: none;
    }
}

/* Mobile */
@media only screen and (max-width: 600px) {
    #logout_button {
        font-size: 0;
        margin: auto;
        padding: 10px;
        padding-left: 0;
        padding-right: 20px;
    }

    #logout_button > #logout_button_icon {
        font-size: 1rem;
    }
}
