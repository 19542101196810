.video_container {
    position: relative;
    object-fit: cover;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    max-height: 100vh;

    /* marge headerbar */
    transform: translateY(-40px);
}

#video {
    min-height: 100%;
    width: 100vw;
}

.image_overlay_video {
    position: fixed;
    overflow: hidden;
    z-index: 100;
    /* transform: translateY(-400px); */
    /* background-color: blue; */
    bottom: 0%;
    /* margin: 0 auto; */
    /* max-height: 100%; */
    max-width: 100;
    width: 10;
    -webkit-filter: invert(1); /* safari 6.0 - 9.0 */
    filter: invert(1);
}

.contenu_flex {
    margin-left: auto;
    margin-right: auto;
    max-width: 1350px;
    /* width: clamp(0vw, 80vw, 90vw); */
}

.categorie {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 50px;
}

.sous_categorie {
    max-width: 600px;
}

.nouvelles_preview_container {
    border-radius: 8px;
    max-width: 800px;
    margin: auto;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.10);
    padding: 5px 30px;
    transition: 0.3s;
    color: var(--text-color-light-bg);
}

.nouvelles_preview_container:hover {
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 20px 35px 0px rgba(0, 0, 0, 0.17);
    padding: 5px 30px;
    transition: 0.3s;
    transform: scale(1.01);
}

@media only screen and (max-width: 1562px) {

    .contenu_flex,
    .categorie {
        margin: auto;
        max-width: 600px;
    }

    .contenu_flex {
        width: 90vw;
    }
}
