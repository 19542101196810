.container {
    margin: auto;
}

.carousel {
    margin: auto;
    width: 60%;
}

.container_contenu {
    margin: auto;
    margin-bottom: 100px;
}

.titre {
    font-size: 4em;
    font-weight: 900;
    word-wrap: break-word;
}

.sous_titre {
    font-size: 1.2em;
}

.contenu {
    font-size: 1.2em;
    color: #363636;
    white-space: pre-wrap;
}

/* Ordinateur */
@media only screen and (min-width: 666px) {
    .container_contenu {
        max-width: 600px;
    }

    .carousel {
        width: 60%;
    }
}

/* Mobile */
@media only screen and (max-width: 665px) {
    .container_contenu {
        width: 90vw;
    }
}

/* Mobile */
@media only screen and (max-width: 800px) {
    .carousel {
        width: 100vw;
    }
}