.grid {
    display: grid;
    place-content: center;
    margin: auto;
    text-align: center;
    gap: 40px;
    max-width: 80vw;
}

/* Ordinateur */
@media only screen and (min-width: 601px) {
    .grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
        width: 80vw;
    }
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .grid {
        grid-template-columns: auto;
    }

    #titre_couleur {
        margin-left: 10vw;
    }
}