.grid {
    display: grid;
    place-content: center;
    margin: auto;
    text-align: center;
    gap: 40px;
    max-width: 80vw;
}

.container_recherche {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* column-gap: 183px; */
    row-gap: 20px;
    margin-bottom: 50px;
}

.select_dropdown {
    border: none;
    padding: 10px;
    border-radius: 8px;
    min-width: 10vw;
}

/* Ordinateur */
@media only screen and (min-width: 601px) {
    .grid {
        grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
        width: 80vw;
    }

    .container_filtre {
        margin-right: 10vw;
    }
}

/* Ordinateur */
@media only screen and (min-width: 691px) {
    .container_filtre {
        text-align: right;
    }

    .container_tri {
        margin-left: 10vw;
    }
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .grid {
        grid-template-columns: auto;
    }
    
    .select_dropdown {
        width: 80vw;
    }
}

/* Mobile */
@media only screen and (max-width: 690px) {
    .container_tri, .container_filtre {
        margin-left: 10vw;
    }
}